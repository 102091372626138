import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AcknowledgementsModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title="Acknowledgements"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className="text-sm text-gray-500 dark:text-gray-300">
        <p>
          A massive thank you to the creators of{' '}
          <a href="https://github.com/cwackerfuss/react-wordle">
            👉 React Wordle 👈
          </a>
          , for building the open-source project that this application is based
          on.
        </p>
        <p>{''}</p>
        <p>
          Advizle customisation was performed by{' '}
          <a href="https://www.linkedin.com/in/sean-flynn-387746194/">
            👉 Sean Flynn 👈
          </a>{' '}
        </p>
      </div>
    </BaseModal>
  )
}
