export const ADVICE_WORDS = [
  {
    word: 'UNIVERSAL',
    clue: '______ credit is a common benefit',
    answer: 'UNIVERSAL credit is a super common benefit.',
    link: 'https://www.citizensadvice.org.uk/benefits/universal-credit/before-you-apply/what-universal-credit-is/',
  },
  {
    word: 'INDEPENDENT',
    clue: 'One of the four principles of Citizens Advice',
    answer:
      'Citizens Advice provides INDEPENDENT advice. We are separate from the local council/housing association/anyone else so clients can trust we are not biased.',
    link: 'https://www.citizensadvice.org.uk/about-us/about-us1/introduction-to-the-citizens-advice-service/',
  },
  {
    word: 'INTESTACY',
    clue: 'When a person dies without leaving a valid will, their property must be shared out according to certain rules. These are called the rules of ________',
    answer:
      'When a person dies without leaving a valid will, their property (the estate) must be shared out according to certain rules. These are called the rules of INTESTACY.',
    link: 'https://www.citizensadvice.org.uk/family/death-and-wills/who-can-inherit-if-there-is-no-will-the-rules-of-intestacy/',
  },
  {
    word: 'MANDATORY',
    clue: "If you don't agree with a benefit decision, you can challenge it with a _________ reconsideration.",
    answer:
      "If you don't agree with a benefit decision, you can challenge it with a MANDATORY reconsideration.",
    link: 'https://www.citizensadvice.org.uk/benefits/benefits-introduction/problems-with-benefits-and-tax-credits/challenging-benefit-decisions/challenging-a-decision-by-the-dwp-or-hmrc/challenging-a-benefit-decision-mandatory-reconsideration/',
  },
  {
    word: 'ENTITLEDTO',
    clue: 'Depending on your situation, you can use the __________ benefit calculator to check which benefits you can get.',
    answer:
      'Depending on your situation, you can use the ENTITLEDTO benefit calculator to check which benefits you can get.',
    link: 'https://www.citizensadvice.org.uk/benefits/benefits-introduction/what-benefits-can-i-get/',
  },
  {
    word: 'WORKING',
    clue: 'Universal Credit has replaced _______ tax credits for most people.',
    answer:
      'Universal Credit has replaced WORKING tax credits for most people.',
    link: 'https://www.citizensadvice.org.uk/benefits/help-if-on-a-low-income/working-and-child-tax-credits/working-and-child-tax-credits/check-if-you-can-get-working-tax-credits/',
  },
  {
    word: 'PENSION',
    clue: 'There are two parts to ________ Credit, called Guarantee Credit and Savings Credit.',
    answer:
      'There are two parts to PENSION Credit, called Guarantee Credit and Savings Credit.',
    link: 'https://www.citizensadvice.org.uk/benefits/help-if-on-a-low-income/pension-credit/before-you-claim-pension-credit/check-if-you-can-get-pension-credit/',
  },
  {
    word: 'SICK',
    clue: 'If you’re employed but you can’t work, you’ll usually get Statutory ____ Pay from your employer for 28 weeks.',
    answer:
      'If you’re employed but you can’t work, you’ll usually get Statutory SICK Pay (SSP) from your employer for 28 weeks.',
    link: 'https://www.citizensadvice.org.uk/work/sick-leave-and-sick-pay/getting-money-when-youre-off-work-sick/',
    //Note: exact phrasing of clue comes from ESA article -  https://www.citizensadvice.org.uk/benefits/sick-or-disabled-people-and-carers/employment-and-support-allowance/help-with-your-esa-claim/check-if-you-can-claim-esa/
  },
  {
    word: 'LIVING',
    clue: 'Disability ______ Allowance isn’t just for children who are physically disabled.',
    answer:
      'Disability LIVING Allowance isn’t just for children who are physically disabled.',
    link: 'https://www.citizensadvice.org.uk/benefits/sick-or-disabled-people-and-carers/disability-living-allowance/before-you-claim-dla/check-if-you-can-get-dla/',
  },
  {
    word: 'ATTENDANCE',
    clue: "Extra money to help with your care needs if you've reached pension age and you have an illness or disability.",
    answer:
      "ATTENDANCE Allowance is extra money to help with your care needs if you've reached pension age and you have an illness or disability.",
    link: 'https://www.citizensadvice.org.uk/benefits/sick-or-disabled-people-and-carers/attendance-allowance/',
  },
  {
    word: 'RAILCARD',
    clue: "If you receive Personal Independence Payment, you can apply for a Disabled Person's ________",
    answer:
      "If you receive Personal Independence Payment, you can apply for a Disabled Person's RAILCARD",
    link: 'https://www.citizensadvice.org.uk/benefits/sick-or-disabled-people-and-carers/pip/before-claiming/extra-help-pip-entitles-you-to/',
  },
  //Employment
  {
    word: 'GRIEVANCE',
    clue: 'If you have a concern, problem or complaint at work, you might want to take this up with your employer.',
    answer:
      "If you have a concern, problem or complaint at work, you might want to take this up with your employer. This is called 'raising a GRIEVANCE'.",
    link: 'https://www.citizensadvice.org.uk/work/grievance-meetings/dealing-with-grievances-at-work/',
  },
  {
    word: 'CONCILIATION',
    clue: 'Acas provides a way to resolve employment-related disputes without going to a tribunal.',
    answer:
      'Acas may see if your employer will agree to a free process called ‘early CONCILIATION’ - a way to resolve disputes without going to a tribunal.',
    link: 'https://www.citizensadvice.org.uk/work/dismissal/check-your-rights-if-youre-dismissed/dismissal/challenge-your-dismissal/',
  },
  {
    word: 'MATERNITY',
    clue: 'You have a right to take up to a year of _________ leave.',
    answer: 'You have a right to take up to a year of MATERNITY leave.',
    link: 'https://www.citizensadvice.org.uk/work/maternity-and-parental-rights/check-your-maternity-and-parental-rights/maternity-leave-and-pay/maternity-leave-what-youre-entitled-to-and-how-to-get-it/',
  },
  //Housing - nothing on homeless help?
  {
    word: 'HEALTH',
    clue: 'You can report your landlord to the Environmental _____ department at your local council if your home is in a bad state of repair.',
    answer:
      'You can report your landlord to the Environmental Health department at your local council if your home is in a bad state of repair.',
    link: 'https://www.citizensadvice.org.uk/housing/renting-privately/during-your-tenancy/dealing-with-repairs/',
  },
  {
    word: 'PRESERVATION',
    clue: 'Before you cut a tree, check if it’s protected by a Tree ____________ Order. ',
    answer:
      'Before you cut a tree, check if it’s protected by a Tree PRESERVATION Order. ',
    link: 'https://www.citizensadvice.org.uk/housing/problems-where-you-live/if-you-disagree-with-your-neighbour-about-a-tree-or-hedge/',
  },
  {
    word: 'LODGER',
    clue: 'A ______ is someone who lives with you in your home and shares living space with you, such as the bathroom or kitchen.',
    answer:
      'A LODGER is someone who lives with you in your home and shares living space with you, such as the bathroom or kitchen.',
    link: 'https://www.citizensadvice.org.uk/housing/renting-a-home/subletting-and-lodging/lodging-index/lodging/',
  },
  //DEBT
  {
    word: 'BANKRUPTCY',
    clue: 'It costs £680 to do this.',
    answer: 'You will need to pay a total fee of £680 to apply for BANKRUPTCY.',
    link: 'https://www.citizensadvice.org.uk/debt-and-money/debt-solutions/bankruptcy/how-to-go-bankrupt/timeline-for-bankruptcy/',
  },
  {
    word: 'PRIORITY',
    clue: "'________ debts' are debts that can cause you particularly serious problems if you don’t do anything about them",
    answer:
      'PRIORITY debts are debts that can cause you particularly serious problems if you don’t do anything about them',
    link: 'https://www.citizensadvice.org.uk/debt-and-money/help-with-debt/dealing-with-your-debts/work-out-which-debts-to-deal-with-first/',
  },
  {
    word: 'WARM',
    clue: 'If you get benefits, you might be able to get £150 off your electricity bill.',
    answer:
      'If you get benefits, you might be able to use the WARM Home Discount Scheme',
    link: 'https://www.citizensadvice.org.uk/consumer/energy/energy-supply/get-help-paying-your-bills/grants-and-benefits-to-help-you-pay-your-energy-bills/',
  },
  {
    word: 'BAILIFF',
    clue: 'A _______ is only allowed to try to come into your home between 6am and 9pm.',
    answer:
      'A BAILIFF only allowed to try to come into your home between 6am and 9pm.',
    link: 'https://www.citizensadvice.org.uk/debt-and-money/action-your-creditor-can-take/bailiffs/stopping-bailiffs/stopping-bailiffs-at-your-door/',
  },
  //VARIOUS
  {
    word: 'MEDIATION',
    clue: '_________ is a way of resolving disputes with the help of a third person who won’t take sides.',
    answer:
      'MEDIATION  is a way of resolving disputes with the help of a third person who won’t take sides.',
    link: 'https://www.citizensadvice.org.uk/family/how-to-separate1/mediation-to-help-you-separate/', //divorce-specific link
  },
  {
    word: 'COOLING',
    clue: 'You automatically get a 14-day ‘_______-off period’ when you buy something you haven’t seen in person - unless it’s bespoke or made to measure.',
    answer:
      'You automatically get a 14-day ‘COOLING-off period’ when you buy something you haven’t seen in person - unless it’s bespoke or made to measure.',
    link: 'https://www.citizensadvice.org.uk/consumer/changed-your-mind/changing-your-mind-about-something-youve-bought/',
  },
  {
    word: 'THREE',
    clue: "You’re entitled to get compensation if your flight arrives more than _ hours late and it's the airline’s fault",
    answer:
      "You’re entitled to get compensation if the flight arrives more than THREE hours late and it's the airline’s fault",
    link: 'https://www.citizensadvice.org.uk/consumer/holiday-cancellations-and-compensation/if-your-flights-delayed-or-cancelled/',
  },
  {
    word: 'CHARGEBACK',
    clue: 'If you want your money back, tell your card provider you want to make a __________ claim.',
    answer:
      'If you want your money back, tell your card provider you want to make a CHARGEBACK claim.',
    link: 'https://www.citizensadvice.org.uk/consumer/somethings-gone-wrong-with-a-purchase/getting-your-money-back-if-you-paid-by-card-or-paypal/',
  },
  {
    word: 'SETTLED',
    clue: 'If an EU citizen has _______ status, then they can apply for benefits.',
    answer:
      'If an EU citizen has SETTLED status, then they can apply for benefits.',
    link: 'https://www.citizensadvice.org.uk/benefits/claiming-benefits-if-youre-from-the-EU/before-you-apply/check-if-you-can-get-benefits-if-youre-from-the-eu/',
  },
  {
    word: 'EARNINGS',
    clue: 'If you owe money for things like a bank loan or credit card, your creditor might try to get a court order to take money from your wages.',
    answer:
      'If you owe money for things like a bank loan or credit card, your creditor may be able to apply for an attachment of EARNINGS order.',
    link: 'https://www.citizensadvice.org.uk/debt-and-money/action-your-creditor-can-take/creditor-takes-money-from-your-wages/',
  },
  {
    word: 'LETTER',
    clue: "Before you start your small claim it's a good idea to do this.",
    answer:
      "Before you start your small claim it's a good idea to write a formal LETTER to the person or business you disagree with. This is called a 'letter before claim' or a 'letter before action'.",
    link: 'https://www.citizensadvice.org.uk/law-and-courts/legal-system/small-claims/making-a-small-claim/',
  },
  {
    word: 'TRADING',
    clue: 'If you think a business has broken the law or acted unfairly, you can report them to ___.',
    answer:
      'If you think a business has broken the law or acted unfairly, you can report them to TRADING Standards.',
    link: 'https://www.citizensadvice.org.uk/consumer/get-more-help/report-to-trading-standards/',
  },
  {
    word: 'LEGAL',
    clue: 'If your case is serious and you can’t afford to pay your legal costs, _____ aid might pay some or all of them.',
    answer:
      'If your case is serious and you can’t afford to pay your legal costs, LEGAL aid might pay some or all of them.',
    link: 'https://www.citizensadvice.org.uk/law-and-courts/legal-system/finding-free-or-affordable-legal-help/',
  },
]
